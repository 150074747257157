/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import { Observable } from 'rxjs';

@Injectable()
export class InMemoryDataService implements InMemoryDbService {
  createDb(): any | Observable<any> | Promise<any> {

    const scorecard = [
      {
        benchmark: 'wha',
        total: {
          hotel: 97,
          benchmark: 85
        },
        series: getSeries(),
        table: [
          { metric: 'Brand.com %', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'OTA %', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'Conversion %', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: '% of Search Traffic', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: '% of MetaSearch Traffic', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: '% of Social Traffic', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: '# of Impressions', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'Social Media Spend', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'OTA Ads Spend', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'OTA Ads ROAS', benchmark: getRandomROAS(), myHotel: getRandomROAS(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'TOTAL', benchmark: '', myHotel: '', benchmarkScore: '85/100', hotelScore: '97/100' },
        ]
      },
      {
        benchmark: 'ypa',
        total: {
          hotel: 100,
          benchmark: 76
        },
        series: getSeries(),
        table: [
          { metric: 'Brand.com %', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'OTA %', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'Conversion %', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: '% of Search Traffic', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: '% of MetaSearch Traffic', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: '% of Social Traffic', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: '# of Impressions', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'Social Media Spend', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'OTA Ads Spend', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'OTA Ads ROAS', benchmark: getRandomROAS(), myHotel: getRandomROAS(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'TOTAL', benchmark: '', myHotel: '', benchmarkScore: '85/100', hotelScore: '97/100' },
        ]
      },
      {
        benchmark: 'cha',
        total: {
          hotel: 92,
          benchmark: 84
        },
        series: getSeries(),
        table: [
          { metric: 'Brand.com %', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'OTA %', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'Conversion %', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: '% of Search Traffic', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: '% of MetaSearch Traffic', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: '% of Social Traffic', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: '# of Impressions', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'Social Media Spend', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'OTA Ads Spend', benchmark: getRandomValue(), myHotel: getRandomValue(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'OTA Ads ROAS', benchmark: getRandomROAS(), myHotel: getRandomROAS(), benchmarkScore: getRandomScore(), hotelScore: getRandomScore() },
          { metric: 'TOTAL', benchmark: '', myHotel: '', benchmarkScore: '85/100', hotelScore: '97/100' },
        ]
      }
    ];


    return {  scorecard };
  }
}

function getSeries() {
  return [
    {
      name: 'Hotel',
      data: [getRandomValue(), getRandomValue(), getRandomValue(), getRandomValue(), getRandomValue()],
    },
    {
      name: 'Benchmark',
      data: [getRandomValue(), getRandomValue(), getRandomValue(), getRandomValue(), getRandomValue()],
    },
  ];
}

function getRandomValue() {
  return Math.floor(Math.random() * 100);
}


function getRandomScore() {
  return Math.floor(Math.random() * 10) + 1 + '/10';
}

function getRandomROAS() {
  const numerator = Math.floor(Math.random() * 10) + 1;
  const denominator = Math.floor(Math.random() * 2) + 1;
  return `${numerator}:${denominator}`;
}
