import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.token) {
    const requiredRole = route.data['role']; // Getting required role from route data
    const user = authService.user; // Getting user from auth service

    if (!requiredRole) {
      return true;
    }

    if (user && user.role.name === requiredRole) {
      return true;  // The user has the required role, allow access
    }

    return false;
  } else {
    // If no token is present, redirect to the login page
    router.navigateByUrl('/login');
    return false;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EnterpriseAccess: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.token) {
    return true;
  } else {
    router.navigateByUrl('/login');
    return false;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const HotelAccess: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.token) {
    return true;
  } else {
    router.navigateByUrl('/login');
    return false;
  }
};
