export const environment = {
  production: true,
  isSentry: false,
  baseUrl: 'https://api.cogwheelmarketing.com/api',
  facebook: {
    appId: '599907791161411',
    cookie: true,
    xfbml: true,
    version: 'v18.0'
  },
  knowledgeBase: {
    cogwheelAnalytic: 'https://docs.google.com/document/d/1C86e4ro1neUOb5s2q79x5jZxOqNXFdFAI6sJbLLw3Lw/edit?usp=sharing'
  },
  hotelRequest: {
    addHotel: 'https://forms.clickup.com/10554421/f/a231n-61251/2UPU35HNZNRCRG4BPB',
    removeHotel: 'https://forms.clickup.com/10554421/f/a231n-62591/CS6XQ8AKPS6DGBKZL4'
  },
  // sentry: {
  //   dsn: "https://73e36f52438526a4043557380e258937@o4507961791938560.ingest.us.sentry.io/4508131605807104"
  // },
  // SENTRY_AUTH_TOKEN:'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',
  googleAnalyticsId: '448496188'
};