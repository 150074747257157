import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '@layout/service/app.layout.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { lastValueFrom } from 'rxjs';

import { AuthService } from '@core/services/auth/auth.service';
import { ResetPassword } from '@modules/auth/models/reset-password';
import { ToastService } from '@layout/service/toast.service';
import { Message } from 'primeng/api';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'cwa-reset-password',
  templateUrl: 'reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  @Input() token!: string;
  @Input() email!: string;

  formGroup!: FormGroup;
  messages!: Message[];
  loading = false;

  control = (name: string): FormControl => this.formGroup.get(name) as FormControl;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private toast: ToastService,
    private authService: AuthService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Auth - Reset Password | Cogwheel Analytics Tools');
}

  ngOnInit(): void {
    this.formGroup = this.fb.group(
      {
        token: [this.token ?? '', [Validators.required]],
        email: [this.email ?? '', [Validators.required, Validators.email]],
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: passwordMatchingValidator }
    );
  }

  get dark(): boolean {
    return this.layoutService.config.colorScheme !== 'light';
  }

  async resetPassword(): Promise<void> {
    if (this.formGroup.invalid) return;
    try {
      this.loading = true;
      await lastValueFrom(
        this.authService.resetPassword(this.formGroup.value as ResetPassword)
      );
      this.loading = false;
      this.toast.showSuccess('Password has been changed successfully.', '');
      await this.router.navigate(['/login']);
    } catch (e) {
      this.loading = false;
      this.messages = [
        {
          severity: 'error',
          summary: 'Password reset was not successful. Please try it again.',
          detail: '',
        },
      ];
    }
  }
}

const passwordMatchingValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const password = control.get('newPassword');
  const confirmPassword = control.get('confirmPassword');

  return password?.value === confirmPassword?.value ? null : { notmatched: true };
};
