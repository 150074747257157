import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommentsQueryParams,
  CommentsResponse,
  GetNotesQueryParam,
  NotesHotelsQueryParams,
  NotesHotelsResponse,
  ScheduleNote,
  Comment,
  AddNoteRequest,
  AddNoteResponse,
  SendNoteNowRequest,
} from '@core/models/schedule';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/notes`;
  }

  getNotes(query: GetNotesQueryParam): Observable<ScheduleNote[]> {
    let params = this.httpParams(query);
    params = params.append('hotel', query.hotels[0]);

    return this.http.get<ScheduleNote[]>(this.baseUrl, { params });
  }

  getNotesHotels(query: NotesHotelsQueryParams): Observable<NotesHotelsResponse> {
    let params = this.httpParams(query);

    if (query?.enterprises) {
      params = params.append('enterprises', query.enterprises);
    } 
    if (query?.brands) {
      params = params.append('brands', query.brands);
    }
    if (query?.hotels) {
      params = params.append('hotels', query.hotels);
    }

    return this.http.get<NotesHotelsResponse>(`${this.baseUrl}/hotels`, { params });
  }

  addComment(body: CommentsQueryParams): Observable<CommentsResponse> {
    return this.http.post<CommentsResponse>(`${this.baseUrl}/comments`, body);
  }

  getComment(noteId: string): Observable<Comment[]> {
    return this.http.get<Comment[]>(`${this.baseUrl}/${noteId}/comments`);
  }

  getComments(hotelId: string): Observable<CommentsResponse> {
    return this.http.get<CommentsResponse>(`${this.baseUrl}/hotels/${hotelId}/comments`);
  }

  addNote(body: AddNoteRequest): Observable<AddNoteResponse> {
    return this.http.post<AddNoteResponse>(`${this.baseUrl}`, body);
  }

  updateNote(noteId: string, body: AddNoteRequest): Observable<AddNoteResponse> {
    const requestBody = {
      ...body,
      note_id: noteId
    }

    return this.http.put<AddNoteResponse>(`${this.baseUrl}/${noteId}/update`, requestBody);
  }

  sendNoteNow(noteId: string, emails: SendNoteNowRequest): Observable<unknown> {
    const requestBody = {
      sendTo: emails
    }

    return this.http.put<unknown>(`${this.baseUrl}/${noteId}/send_note_now`, requestBody);
  }

  private httpParams(
    query: GetNotesQueryParam | NotesHotelsQueryParams | string | any
  ): HttpParams {
    let params = new HttpParams();

    if (query?.from && query?.to) {
      params = params.append('dateFrom', `${query.from}`);
      params = params.append('dateTo', `${query.to}`);
    }

    return params;
  }
}
