import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  private _showProgress$ = new Subject<boolean>();
  showProgress$ = this._showProgress$.asObservable();

  beginProgress(): void {
    this._showProgress$.next(true);
  }

  endProgress(): void {
    this._showProgress$.next(false);
  }
}
