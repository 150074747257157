import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserRole } from '@core/models';
import { environment } from '@env/environment';
import { PageContent, QueryParam } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/role`;
  }

  getAll(param: QueryParam): Observable<PageContent<UserRole>> {
    const params = new HttpParams()
      .append('page', param.page ?? 1)
      .append('size', param.size ?? 50)
      .append('search', param.search ?? '');

    return this.http.get<PageContent<UserRole>>(this.baseUrl, { params });
  }

  create(role: UserRole): Observable<UserRole> {
    return this.http.post<UserRole>(this.baseUrl, role);
  }

  update(role: UserRole): Observable<UserRole> {
    return this.http.put<UserRole>(`${this.baseUrl}/${role.id}`, role);
  }

  delete(role: UserRole): Observable<unknown> {
    return this.http.delete<unknown>(`${this.baseUrl}/${role.id}`);
  }
}
