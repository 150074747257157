import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { environment } from '@env/environment';
import { CreateHotelContactResponse, Hotel, HotelContactsResponse } from '@core/models';
import { PageContent } from '@shared/models';
import { HotelQueryParam } from '@shared/models/hotel-query-param';

@Injectable({
  providedIn: 'root',
})
export class HotelService {
  private readonly baseUrl: string;
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/hotel`;
  }

  getAll(param: HotelQueryParam): Observable<PageContent<Hotel>> {
    // Ennable the loading state.
    this.loading$.next(true)
    const params = new HttpParams()
      .append('page', param.page ?? 1)
      .append('size', param.size ?? 100)
      .append('search', param?.search ?? '')
      .append('orderBy', param?.orderBy ?? '')
      .append('enterprises', (param.enterprises ?? []).join(','))
      .append('brands', (param.brands ?? []).join(','));

    return this.http.get<PageContent<Hotel>>(this.baseUrl, { params }).pipe(tap(() => {
      this.loading$.next(false)
    }));
  }

  get(id: string): Observable<Hotel> {
    return this.http.get<Hotel>(`${this.baseUrl}/${id}`);
  }

  create(hotel: Hotel): Observable<Hotel> {
    return this.http.post<Hotel>(this.baseUrl, hotel);
  }

  createContact(id: string, body: any): Observable<CreateHotelContactResponse> {
    return this.http.post<CreateHotelContactResponse>(`${this.baseUrl}/${id}/contacts`, body);
  }

  getContact(id: string): Observable<HotelContactsResponse[]> {
    return this.http.get<HotelContactsResponse[]>(`${this.baseUrl}/${id}/contacts`);
  }

  update(hotel: Hotel): Observable<Hotel> {
    return this.http.put<Hotel>(`${this.baseUrl}/${hotel.id}`, hotel);
  }

  delete(hotel: Hotel): Observable<unknown> {
    return this.http.delete<unknown>(`${this.baseUrl}/${hotel.id}`);
  }

  deleteContact(contactId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/contacts/${contactId}`);
  }
}
