import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class QueryParamGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const queryParams = route.queryParams;

    if (queryParams && queryParams['email']) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

  constructor(private router: Router) {}
}
