import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { TopRefDomain, TopRefDomainBrand, TopRefDomainHotel } from '@core/models';
import { TopRefDomainQuery } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class TopRefDomainService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    // this.baseUrl = 'api/topRefDomain';
    this.baseUrl = `${environment.baseUrl}/v1/top_ref`;
  }

  getAll(): Observable<TopRefDomain[]> {
    return this.http.get<TopRefDomain[]>(this.baseUrl);
  }

  getBrands(query: TopRefDomainQuery): Observable<TopRefDomainBrand[]> {
    const params = this.httpParam(query);
    return this.http.get<TopRefDomainBrand[]>(`${this.baseUrl}/brand_top_ref`, {
      params,
    });
  }

  getHotels(brandId: string, query: TopRefDomainQuery): Observable<TopRefDomainHotel[]> {
    let params = this.httpParam(query);
    params = params.append('brand_id', brandId);
    return this.http.get<TopRefDomainBrand[]>(`${this.baseUrl}/hotels_top_ref_data`, {
      params,
    });
  }

  getHotel(hotelId: string, query: TopRefDomainQuery): Observable<TopRefDomainHotel[]> {
    let params = this.httpParam(query);
    params = params.append('hotel_id', hotelId);
    return this.http.get<TopRefDomainBrand[]>(`${this.baseUrl}/hotel_top_ref_data`, {
      params,
    });
  }

  private httpParam(query: TopRefDomainQuery): HttpParams {
    let params = new HttpParams()
      .append('dateFrom', `${query.from}`)
      .append('dateTo', `${query.to}`)
      .append('is_compare', !!query.isCompare);

    if (query.enterprises && query.enterprises.length) {
      params = params.append('enterprises', query.enterprises.join(','));
    }

    if (query.brands && query.brands.length) {
      params = params.append('brands', query.brands.join(','));
    }

    if (query.hotels && query.hotels.length) {
      params = params.append('hotels', query.hotels.join(','));
    }

    if (query.defaultMonthDate) {
      params = params.append('defaultMonthDate', `${query?.defaultMonthDate}`);
    }

    if (query?.compDateFrom && query.compDateTo) {
      params = params
        .append('compDateFrom', query?.compDateFrom ?? '')
        .append('compDateTo', query?.compDateTo ?? '');
    }

    params = query.predefineDate
      ? params.append('predefineDate', query?.predefineDate ?? '')
      : params;

    return params;
  }
}
