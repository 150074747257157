import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  SourceItems,
  SourceTrafficBrand,
  SourceTrafficGraph,
  SourceTrafficHotel,
  SourceTrafficLegacy,
} from '@core/models';
import { SourceQueryParam } from '@shared/models/source-query-param';

@Injectable({
  providedIn: 'root',
})
export class SourceTrafficService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/source`;
  }

  getAll(): Observable<SourceTrafficLegacy[]> {
    return this.http.get<SourceTrafficLegacy[]>(this.baseUrl);
  }

  getBrands(query: SourceQueryParam): Observable<SourceTrafficBrand[]> {
    const params = this.httpParams(query);
    return this.http.get<SourceTrafficBrand[]>(`${this.baseUrl}/brand_source_traffic`, {
      params,
    });
  }

  getHotels(brandId: string, query: SourceQueryParam): Observable<SourceTrafficHotel[]> {
    let params = this.httpParams(query);
    params = params.append('brand_id', brandId);
    return this.http.get<SourceTrafficHotel[]>(`${this.baseUrl}/hotels_source_traffic`, {
      params,
    });
  }

  getHotel(hotelId: string, query: SourceQueryParam): Observable<SourceTrafficHotel[]> {
    let params = this.httpParams(query);
    params = params.append('hotel_id', hotelId);
    return this.http.get<SourceTrafficHotel[]>(`${this.baseUrl}/hotel_source_traffic`, {
      params,
    });
  }

  getGraph(query: SourceQueryParam): Observable<SourceTrafficGraph[]> {
    const params = this.httpParams(query);
    return this.http.get<SourceTrafficGraph[]>(`${this.baseUrl}/source_traffic_graph`, {
      params,
    });
  }

  getSources(): Observable<SourceItems> {
    return this.http.get<SourceItems>(`${this.baseUrl}`);
  }

  private httpParams(query: SourceQueryParam): HttpParams {
    let params = new HttpParams()
      .append('dateFrom', `${query.from}`)
      .append('dateTo', `${query.to}`)
      .append('is_compare', !!query.isCompare);

    if (query.enterprises && query.enterprises.length) {
      params = params.append('enterprises', query.enterprises.join(','));
    }

    if (query.brands && query.brands.length) {
      params = params.append('brands', query.brands.join(','));
    }

    if (query.hotels && query.hotels.length) {
      params = params.append('hotels', query.hotels.join(','));
    }

    if (query.defaultMonthDate) {
      params = params.append('defaultMonthDate', `${query?.defaultMonthDate}`);
    }

    if (query?.compDateFrom && query.compDateTo) {
      params = params
        .append('compDateFrom', query?.compDateFrom ?? '')
        .append('compDateTo', query?.compDateTo ?? '');
    }

    if (query?.sourceId && query?.sourceId.length) {
      params = params.append('sourceId', query?.sourceId.join(','));
    }

    params = query.predefineDate ? params
      .append('predefineDate', query?.predefineDate ?? '') : params;

    return params;
  }
}
