// eslint-disable @typescript-eslint/no-explicit-any
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { ProgressService } from '@layout/service/progress.service';
import { ToastService } from '@layout/service/toast.service';

@Injectable()
export class ReportingInterceptor implements HttpInterceptor {
  constructor(
    private progress: ProgressService,
    private toast: ToastService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.progress.beginProgress();
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          const message = error?.error?.detail ? error.error.detail : error.message
          this.toast.showError('Error', message);
        }
        return throwError(error);
      }),
      finalize(() => {
        this.progress.endProgress();
      })
    );
  }
}

export const reportingInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: ReportingInterceptor,
  deps: [ProgressService, ToastService],
  multi: true,
};
