import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private messageService: MessageService) {}

  showSuccess(summary: string = 'Success', message: string = '') {
    this.messageService.add({
      key: 'toast',
      severity: 'success',
      summary: summary,
      detail: message,
    });
  }

  showInfo(summary: string = 'Info', message: string = '') {
    this.messageService.add({
      key: 'toast',
      severity: 'info',
      summary: summary,
      detail: message,
    });
  }

  showWarn(summary: string = 'Warn', message: string = '') {
    this.messageService.add({
      key: 'toast',
      severity: 'warn',
      summary: summary,
      detail: message,
    });
  }

  showError(summary: string = 'Error', message: string = '') {
    this.messageService.add({
      key: 'toast',
      severity: 'error',
      summary: summary,
      detail: message,
    });
  }
}
