import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AuthService } from '@core/services/auth/auth.service';
import { Router } from '@angular/router';
import { User } from '@core/models';
import { environment } from "@env/environment";

@Component({
  selector: 'app-profilemenu',
  templateUrl: './app.profilesidebar.component.html',
})
export class AppProfileSidebarComponent {
  user: User | null;

  constructor(
    public layoutService: LayoutService,
    private router: Router,
    private authService: AuthService
  ) {
    this.user = this.authService.user;
  }

  get name(): string {
    if (!this.user) return '';
    const { firstName, lastName } = this.user;
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }

  get visible(): boolean {
    return this.layoutService.state.profileSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.profileSidebarVisible = _val;
  }

  logout(): void {
    this.authService.logout();
    this.visible = false;
    this.router.navigateByUrl('/login');
  }

  openKnowledgeBase() {
    window.open(environment.knowledgeBase.cogwheelAnalytic);
  }

  addRemoveHotel(request: 'add' | 'remove') {
    if (request === 'add')
    {
      window.open(environment.hotelRequest.addHotel);
    } else {
      window.open(environment.hotelRequest.removeHotel);
    }
  }
}
