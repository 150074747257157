import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  VisitRevenueBrand,
  VisitRevenueCompareDetail,
  VisitRevenueDetail,
  VisitRevenueGraph,
  VisitRevenueHotel,
  VisitsRevenueDetails,
} from '@core/models';
import { VisitQueryParam } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class VisitRevenueService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/visits`;
  }

  getBrands(query: VisitQueryParam): Observable<VisitRevenueBrand[]> {
    const params = this.httpParam(query);
    return this.http.get<VisitRevenueBrand[]>(`${this.baseUrl}/brand_visits_rev`, {
      params,
    });
  }

  getHotels(brandId: string, query: VisitQueryParam): Observable<VisitRevenueHotel[]> {
    let params = this.httpParam(query);
    params = params.append('brand_id', brandId);
    return this.http.get<VisitRevenueHotel[]>(`${this.baseUrl}/hotels_visits_rev`, {
      params,
    });
  }

  getHotel(hotelId: string, query: VisitQueryParam): Observable<VisitRevenueHotel[]> {
    let params = this.httpParam(query);
    if (!params.has('hotel_id')) params = params.append('hotel_id', hotelId);

    return this.http.get<VisitRevenueHotel[]>(`${this.baseUrl}/hotel_visits_rev`, {
      params,
    });
  }

  getGraph(query: VisitQueryParam): Observable<VisitRevenueGraph[]> {
    const params = this.httpParam(query);
    return this.http.get<VisitRevenueGraph[]>(`${this.baseUrl}/visits_rev_graph`, {
      params,
    });
  }

  getDetails(query: VisitQueryParam): Observable<VisitsRevenueDetails[]> {
    const params = this.httpParam(query);
    return this.http.get<VisitsRevenueDetails[]>(`${this.baseUrl}/visits_rev_detailed`, {
      params,
    });
  }

  getDetailsNew(
    hotelId: string,
    queryParam: VisitQueryParam
  ): Observable<VisitRevenueDetail | VisitRevenueCompareDetail[]> {
    const params = this.httpParam({ ...queryParam, hotelId });
    return this.http.get<VisitRevenueDetail | VisitRevenueCompareDetail[]>(
      `${this.baseUrl}/visits_rev_hotel_detailed_new`,
      { params }
    );
  }

  private httpParam(query: VisitQueryParam): HttpParams {
    let params = new HttpParams()
      .append('dateFrom', `${query.from}`)
      .append('dateTo', `${query.to}`)
      .append('is_compare', !!query?.isCompare);

    if (query.hotelId) {
      params = params.append('hotel_id', query.hotelId);
    }

    if (query.enterprises && query.enterprises.length) {
      params = params.append('enterprises', query.enterprises.join(','));
    }

    if (query.brands && query.brands.length) {
      params = params.append('brands', query.brands.join(','));
    }

    if (query.hotels && query.hotels.length) {
      params = params.append('hotels', query.hotels.join(','));
    }

    if (query.defaultMonthDate) {
      params = params.append('defaultMonthDate', `${query?.defaultMonthDate}`);
    }

    if (query?.compDateFrom && query.compDateTo) {
      params = params
        .append('compDateFrom', query?.compDateFrom ?? '')
        .append('compDateTo', query?.compDateTo ?? '');
    }

    params = query.predefineDate
      ? params.append('predefineDate', query?.predefineDate ?? '')
      : params;

    return params;
  }
}
