<div style="background: url('assets/images/bg.jpg');" class="fixed left-0 top-0 min-h-screen min-w-screen"></div>

<form [formGroup]="loginForm" (ngSubmit)="login()">
  <div class="px-5 min-h-screen flex justify-content-center align-items-center">
    <div style="box-shadow: 3px 2px 33px -17px rgba(0,0,0,0.75);"
      class="border-1 surface-border surface-card border-round py-6 px-4 md:px-6 z-1">
      <div class="mb-4 w-full">
        <img src="assets/images/png/logo.png" width="150" alt="CogwheelMarketing Logo">
        <div class="text-900 text-xl font-bold mb-2 mt-3">Log In</div>
        <span class="text-500">Please enter your login details</span>
        <p-messages [(value)]="messages" [enableService]="false" [closable]="false" />
      </div>
      <div class="flex flex-column">
        <div class="p-fluid mb-4">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-envelope"></i>
            <input id="email" formControlName="email" type="email" pInputText class="w-full md:w-25rem"
              placeholder="Email">
          </span>
          <small id="email-help" class="p-error" *ngIf="controls.email?.hasError('required') && controls.email?.dirty">
            Email is required.
          </small>
          <small id="username-help" class="p-error" *ngIf="controls.email?.hasError('email') && controls.email?.dirty">
            Please enter a valid email.
          </small>
        </div>


        <div class="p-fluid mb-4">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-lock"></i>
            <input id="password" type="password" formControlName="password" pInputText class="w-full md:w-25rem"
              placeholder="Password">
          </span>
          <small id="password-help" class="p-error"
            *ngIf="controls.password?.hasError('required') && controls.password?.dirty">
            Password is required.
          </small>
        </div>

        <div class="mb-4 flex flex-wrap gap-3">
          <div>
            <p-checkbox id="checkbox" name="checkbox" value="val" styleClass="mr-2" [binary]="true"></p-checkbox>
            <label for="checkbox" class="text-900 font-medium mr-8">Remember Me</label>
          </div>
          <a [routerLink]="['/forgot-password']"
            class="text-600 cursor-pointer hover:text-primary cursor-pointer ml-auto transition-colors transition-duration-300">Reset
            password</a>
        </div>

        

        <div class="">
          <p-dialog header="T&C Agreement" [modal]="true" [(visible)]="visible" (onHide)="cancelAgree()" [style]="{ width: '25rem' }">
              <div class="p-fluid mb-4">
                <div class="flex align-items-center gap-2">
                  <label for="agree" class="text-900 font-medium"> I agree to the
                    <a href="https://cogwheelanalytics.com/terms-conditions/" target="_blank"
                      class="text-600 cursor-pointer hover:text-primary cursor-pointer ml-auto transition-colors transition-duration-300 underline">
                      terms of use</a>
                    and <a href="https://cogwheelanalytics.com/privacy-policy/" target="_blank"
                      class="text-600 cursor-pointer hover:text-primary cursor-pointer ml-auto transition-colors transition-duration-300 underline">privacy
                      policy</a>.</label>
                </div>
              </div>
              <div class="flex justify-content-end gap-2">
                  <p-button label="Cancel" severity="secondary" (click)="cancelAgree()" />
                  <p-button label="Agree" (click)="doAgree()" />
              </div>
          </p-dialog>
        </div>
      

      <button type='submit' pButton pRipple label="Log In" [loading]="loading" class="w-full"></button>
      </div>
    </div>
  </div>
</form>
