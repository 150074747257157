/* eslint-disable @typescript-eslint/no-explicit-any */
import { environment } from '@env/environment';
import { Storage } from '@core/utils/storage';

export function initializeApp() {
  return () =>
    new Promise((resolve) => {
      /*if (authService.token) {
      await lastValueFrom(authService.profile());
    }*/

      window['fbAsyncInit'] = () => {
        FB.init({
          appId: environment.facebook.appId,
          cookie: environment.facebook.cookie,
          xfbml: environment.facebook.xfbml,
          version: environment.facebook.version,
        });

        // auto authenticate with the api if already logged in with facebook
        FB.getLoginStatus(({ authResponse }) => {
          if (authResponse) {
            Storage.setItem('fbAccessToken', authResponse.accessToken);
          } else {
            Storage.removeItem('fbAccessToken');
          }
          resolve(true);
        });
      };

      // load facebook sdk script
      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s);
        js.id = id;
        (js as any).src = 'https://connect.facebook.net/en_US/sdk.js';
        (fjs as any).parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    });
}
