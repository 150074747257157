import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

import { CoreModule } from '@core/core.module';
import { AppLayoutModule } from '@layout/app.layout.module';
import { AuthModule } from '@modules/auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InMemoryDataService } from './data/in-memory-data.service';

import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { environment } from '@env/environment';
import { GoogleAnalyticsService } from 'src/app-initializer';

const CORE_MODULES = [
  BrowserModule,
  ReactiveFormsModule,
  HttpClientModule,
  CoreModule,
  AppRoutingModule,
  AppLayoutModule,
  AuthModule,
  HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
    dataEncapsulation: false,
    passThruUnknownUrl: true,
  }),
];

export function initializeApp(
  sentryTraceService: Sentry.TraceService,
  googleAnalyticsService: GoogleAnalyticsService
) {
  return () => {
    googleAnalyticsService.initialize();
  }
}

@NgModule({
  declarations: [AppComponent],
  providers: [
    GoogleAnalyticsService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: !environment.production,  // Show dialog only when not in production
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Sentry.TraceService, GoogleAnalyticsService],
      multi: true,
    },
  ],
  imports: [...CORE_MODULES],
  bootstrap: [AppComponent],
})
export class AppModule {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(trace: Sentry.TraceService) {}
}
