import { Injectable } from '@angular/core';
import { environment } from './environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  initialize(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
      document.head.appendChild(script);

      // Configure gtag after the script loads
      script.onload = () => {
        window.dataLayer = window['dataLayer'] || [];
        function gtag(...args: any[]) {
          window.dataLayer.push(args);
        }
        gtag('js', new Date());
        gtag('config', environment.googleAnalyticsId);
      };
    });
  }
}