import { APP_INITIALIZER, NgModule } from '@angular/core';

import { environment } from '@env/environment';
import { LogLevel } from './services/logger/config/log-level.enum';
import { LOGGER_CONFIG } from './services/logger/config/logger-config';
import { initializeApp } from './factories/initialize.factory';
import { reportingInterceptor } from './interceptores/reporting.interceptor';
import { tokenInterceptor } from './interceptores/token.interceptor';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
    },
    reportingInterceptor,
    tokenInterceptor,
    {
      provide: LOGGER_CONFIG,
      useValue: environment.production ? LogLevel.WARN : LogLevel.VERBOSE,
    },
  ],
})
export class CoreModule {}
