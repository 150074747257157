import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';


export enum BenchmarkType {
  WEIGHTED_HISTORICAL = 'weighted_historical',
  ENTERPRISE = 'enterprise',
  CHAIN_SCALE = 'chain_scale',
}

export interface BenchmarkScore {
  aggregated_score: number | null;
  historical_scores: Record<number, number>;
}

export interface BenchmarkData {
  current_kpis: Record<string, number>;
  average_kpis: Record<string, number>;
  index_scores: Record<string, number>;
  personal_final_score: number | null;
  benchmark_final_score: number | BenchmarkScore;
  previous_personal_final_score: number | null;
  previous_benchmark_final_score: number | BenchmarkScore;
  previous_month: string;
  rolling_12_month_personal_final_scores: Array<{ date: string; personal_final_score: number | null }>;
  rolling_12_month_benchmark_final_scores: Array<{ date: string; benchmark_final_score: number | BenchmarkScore }>;
  benchmark_sample_size: number | null;
}

export interface MonthlyKPIData {
  kpis: Record<string, number>;
  sample_size: Record<string, number>;
}


export interface Benchmark4Data {
  average_kpis: Record<string, number>;
  sample_sizes: Record<string, number>;
  monthly_kpis: Record<string, MonthlyKPIData>;
  time_range: string;
}

export interface ScorecardResponse {
  benchmark1: BenchmarkData;
  benchmark2: BenchmarkData;
  benchmark3: BenchmarkData;
  benchmark4?: Benchmark4Data;
}

export interface BenchmarkRollupData {
  benchmark_type: BenchmarkType;
  group_id: string | null;
  group_name: string | null;
  start_date: string;
  end_date: string;
  hotels: HotelRollupScorecard[];
  group_average_index_scores: Record<string, number | null>;
  group_average_personal_final_score: number | null;
  sample_size: number | null;
}

export interface HotelRollupScorecard {
  hotel_id: string;
  hotel_name: string;
  average_index_scores: Record<string, number | null>;
  average_personal_final_score: number | null;
}

export interface ScorecardRollupResponse {
  start_date: string;
  end_date: string;
  benchmarks: {
    [key in BenchmarkType]: BenchmarkRollupData[];
  };
}

export interface HotelMonthlyRollupData {
  hotel_id: string;
  hotel_name: string;
  monthly_data: Record<string, Record<string, number | null>>;
}


@Injectable({
  providedIn: 'root',
})
export class ScorecardService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/scorecard`;
  }

  getBenchmark4(date: string, timeRange: string): Observable<Benchmark4Data> {
    const url = `${this.baseUrl}/scorecard/benchmark4`;
    const params = new HttpParams()
        .set('date', date)  
        .set('time_range', timeRange);  

    return this.http.get<Benchmark4Data>(url, { params }).pipe(
        catchError(this.handleError)
    );
}

  getScorecard(
    hotelId: string, 
    date: string, 
    enterpriseId?: string | null, 
    benchmark4TimeRange?: string
  ): Observable<ScorecardResponse> {
    const url = `${this.baseUrl}/scorecard`;
    let params = new HttpParams()
      .set('hotel_id', hotelId)
      .set('date', date);

    if (enterpriseId) {
      params = params.set('enterprise_id', enterpriseId);
    }

    if (benchmark4TimeRange) {
      params = params.set('benchmark4_time_range', benchmark4TimeRange);
    }

    return this.http.get<ScorecardResponse>(url, { params }).pipe(
      catchError(this.handleError)
    );
  }

  getScorecardRollup(
    groupIds: string[] | null,
    startDate: string,
    endDate: string,
    benchmarkTypes: BenchmarkType[]
  ): Observable<ScorecardRollupResponse> {
    const url = `${this.baseUrl}/scorecard-rollup`;
    let params = new HttpParams()
      .set('start_date', startDate)
      .set('end_date', endDate)
  
    if (groupIds && groupIds.length > 0) {
      groupIds.forEach(id => {
        params = params.append('group_ids', id);
      });
    }

    if (benchmarkTypes && benchmarkTypes.length > 0) {
      params = params.set('benchmark_types', benchmarkTypes.join(','));
    }
  
    return this.http.get<ScorecardRollupResponse>(url, { params }).pipe(
      catchError(this.handleError)
    );
  }

  getHotelMonthlyRollupData(
    benchmarkType: 'enterprise' | 'chain_scale' | 'weighted_historical',
    groupId: string,
    hotelId: string,
    startDate: string,
    endDate: string
  ): Observable<HotelMonthlyRollupData> {
    const url = `${this.baseUrl}/hotel-monthly-rollup-data`;
    const params = new HttpParams()
      .set('benchmark_type', benchmarkType)
      .set('group_id', groupId)
      .set('hotel_id', hotelId)
      .set('start_date', startDate)
      .set('end_date', endDate);

      return this.http.get<HotelMonthlyRollupData>(url, { params }).pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error);
    let errorMessage = 'An unknown error occurred';
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Backend returned an unsuccessful response code
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => new Error(errorMessage));
  }
}

