import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  BrandChannelMix,
  ChannelMix,
  ChannelMixGraph,
  HotelChannelMix,
} from '@core/models';
import { ChannelMixDetailsQuery, ChannelMixQueryParam } from '@shared/models';
import { ChannelMixCompare, ChannelMixDetailedYoY, ChannelMixSingle, ChannelMonth } from '@modules/brand/models';

@Injectable({
  providedIn: 'root',
})
export class ChannelMixService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/channel`;
  }

  getAll(): Observable<ChannelMix[]> {
    return this.http.get<ChannelMix[]>(this.baseUrl);
  }

  getBrandChannelMix(queryParam?: ChannelMixQueryParam): Observable<BrandChannelMix[]> {
    const params = this.getHttpsParams(queryParam as ChannelMixQueryParam);

    return this.http.get<BrandChannelMix[]>(`${this.baseUrl}/brand_channelmix`, {
      params,
    });
  }

  getHotelsChannelMix(brandId: string, queryParam?: ChannelMixQueryParam): Observable<HotelChannelMix[]> {
    const params = this.getHttpsParams(queryParam as ChannelMixQueryParam).
      append('brand_id', brandId)

    return this.http.get<HotelChannelMix[]>(`${this.baseUrl}/hotels_channelmix`, {
      params,
    });
  }

  getHotelChannelMix(hotelId: string, queryParam?: ChannelMixQueryParam): Observable<HotelChannelMix[]> {
    const params = this.getHttpsParams(queryParam as ChannelMixQueryParam)
      .append('hotel_id', hotelId);

    return this.http.get<HotelChannelMix[]>(`${this.baseUrl}/hotel_channelmix`, {
      params,
    });
  }

  getGraph(id?: string, queryParam?: ChannelMixQueryParam): Observable<ChannelMixGraph[]> {
    let params = this.getHttpsParams(queryParam as ChannelMixQueryParam);
    params = id ? params.append('hotel_id', id ?? '') : params;

    return this.http.get<ChannelMixGraph[]>(`${this.baseUrl}/hotel_channelmix_graph`, {
      params,
    });
  }

  getHotelDetail(hotelId: string, queryParam?: ChannelMixDetailsQuery): Observable<ChannelMonth> {
    const params = this.getHttpsParams(queryParam as ChannelMixDetailsQuery)
      .append('hotel_id', hotelId);

    return this.http.get<ChannelMonth>(`${this.baseUrl}/channel_mix_hotel_detailed`, {
      params,
    });
  }

  getHotelDetailYoY(hotelId: string, queryParam?: ChannelMixDetailsQuery): Observable<ChannelMixDetailedYoY> {
    const params = this.getHttpsParams(queryParam as ChannelMixDetailsQuery)
      .append('hotel_id', hotelId);

    return this.http.get<ChannelMixDetailedYoY>(`${this.baseUrl}/channel_mix_hotel_detailed_yoy`, {
      params,
    });
  }

  getHotelDetailNew(
    hotelId: string,
    queryParam?: ChannelMixDetailsQuery
  ): Observable<ChannelMixSingle[] | ChannelMixCompare[]> {
    const params = this.getHttpsParams(queryParam as ChannelMixDetailsQuery).append(
      'hotel_id',
      hotelId
    );

    return this.http.get<ChannelMixSingle[] | ChannelMixCompare[]>(`${this.baseUrl}/channel_mix_detailled_new`, { params });
  }

  getHttpsParams(query: ChannelMixQueryParam | ChannelMixDetailsQuery): HttpParams {
    let params = new HttpParams()
      .append('dateFrom', `${query?.from}`)
      .append('dateTo', `${query?.to}`)
      .append('is_compare', !!query?.isCompare);

    if (query?.enterprises && query.enterprises.length) {
      params = params.append('enterprises', query?.enterprises?.join(','));
    }

    if (query?.brands && query.brands.length) {
      params = params.append('brands', query?.brands?.join(','));
    }

    if (query?.hotels && query.hotels.length) {
      params = params.append('hotels', query?.hotels?.join(','));
    }

    if (query?.compDateFrom && query.compDateTo) {
      params = params
        .append('compDateFrom', query?.compDateFrom ?? '')
        .append('compDateTo', query?.compDateTo ?? '');
    }

    params = query.defaultMonthDate
      ? params.append('defaultMonthDate', `${query?.defaultMonthDate}`)
      : params;
    params = query.predefineDate
      ? params.append('predefineDate', `${query?.predefineDate}`)
      : params;

    return params;
  }
}
