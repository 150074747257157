<div style="background: url('assets/images/bg.jpg');" class="fixed left-0 top-0 min-h-screen min-w-screen"></div>

<div class="px-5 min-h-screen flex justify-content-center align-items-center">
  <div style="box-shadow: 3px 2px 33px -17px rgba(0,0,0,0.75);" class="border-1 surface-border surface-card border-round py-7 px-4 md:px-7 z-1">
    <div class="mb-4">
      <img src="assets/images/png/logo.png" width="150" alt="CogwheelMarketing Logo">
    </div>
    <div class="mb-4">
      <div class="text-900 text-xl font-bold mb-2">Forgot Password</div>
      <span class="text-600 font-medium">Enter your email to reset your password</span>
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="requestPasswordReset()">
      <div class="flex flex-column">
          <div class="w-full md:w-25rem mb-4">
            <p-messages [(value)]="messages" [enableService]="false" class="w-full md:w-25rem" />

            <span class="p-input-icon-left w-full ">
              <i class="pi pi-envelope"></i>
              <input id="email" type="email" formControlName="email" pInputText class="w-full" placeholder="Email">
            </span>

            <small
              *ngIf="control('email').invalid &&
                   (control('email').dirty || control('email').touched)"
              id="email-help" class="p-error w-full">
              <ng-container *ngIf="control('email').hasError('required')">
                Email is required.
              </ng-container>
              <ng-container *ngIf="control('email').hasError('email')">
                Please enter a valid email.
              </ng-container>
            </small>
          </div>

          <div class="flex flex-wrap gap-2 justify-content-between">
            <button
              pButton pRipple label="Cancel"
              class="flex-auto p-button-outlined"
              [routerLink]="['/login']">
            </button>
            <button
              pButton pRipple type="submit"
              [loading]="loading" label="Submit"
              [disabled]="formGroup.invalid" class="flex-auto">
            </button>
          </div>
      </div>
    </form>
  </div>
</div>
