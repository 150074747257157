import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { FacebookPost, FacebookReaction, PageInsight } from '@core/models';
import { FacebookQuery, PageContent } from '@shared/models';
import { Platform } from '@modules/social/models/platform';
import { FacebookAdCampaignResponse } from '@modules/social/models/facebook-ad-campaign';
import { FacebookAdset } from '@modules/social/models/facebook-adset';
import { FacebookAd } from '@modules/social/models/facebook-ad';

@Injectable({
  providedIn: 'root',
})
export class FacebookService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/facebook`;
  }

  getPageInsights(query: FacebookQuery): Observable<PageInsight[]> {
    const params = this.httpParams(query);
    return this.http.get<PageInsight[]>(`${this.baseUrl}/page_insights`, { params });
  }

  getSocialMediaOverview(query: FacebookQuery): Observable<PageInsight[]> {
    const params = this.httpParams(query);
    return this.http.get<PageInsight[]>(`${this.baseUrl}/social_media_overview`, { params });
  }

  getPostReaction(query: FacebookQuery): Observable<FacebookReaction> {
    const params = this.httpParams(query);
    return this.http.get<FacebookReaction>(`${this.baseUrl}/post_insights`, { params });
  }

  getPosts(query: FacebookQuery): Observable<FacebookPost[]> {
    const params = this.httpParams(query);
    return this.http.get<FacebookPost[]>(`${this.baseUrl}/post`, { params });
  }

  getPlatforms(): Observable<PageContent<Platform>> {
    return this.http.get<PageContent<Platform>>(`${this.baseUrl}/platform`);
  }

  getAdCampaign(hotelId: string, query: FacebookQuery): Observable<FacebookAdCampaignResponse> {
    let params = this.httpParams(query);
    params = params.append('hotel_id', hotelId);

    return this.http.get<FacebookAdCampaignResponse>(`${this.baseUrl}/campaign_meta_ads`, {
      params,
    });
  }

  getAdSets(campaignId: string, query: FacebookQuery): Observable<FacebookAdset[]> {
    let params = this.httpParams(query);
    params = params.append('campaign_id', campaignId);

    return this.http.get<FacebookAdset[]>(`${this.baseUrl}/adset_meta_ads`, { params });
  }

  getAds(adSetId: string, query: FacebookQuery): Observable<FacebookAd[]> {
    let params = this.httpParams(query);
    params = params.append('adset_id', adSetId);
    return this.http.get<FacebookAd[]>(`${this.baseUrl}/get_meta_ads`, { params });
  }

  private httpParams(query: FacebookQuery): HttpParams {
    let params = new HttpParams()
      .append('dateFrom', `${query?.from}`)
      .append('dateTo', `${query?.to}`);

    if (query?.enterprises && query.enterprises.length) {
      params = params.append('enterprises', query?.enterprises?.join(','));
    }

    if (query?.brands && query.brands.length) {
      params = params.append('brands', query?.brands?.join(','));
    }

    if (query?.hotels && query.hotels.length) {
      params = params.append('hotels', query?.hotels?.join(','));
    }

    params = query?.platformId ? params.append('platformId', query.platformId) : params;

    return params;
  }
}
