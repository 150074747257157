import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

import { GlobalFilter } from '@core/models/global-filter';

interface GlobalFilterProp {
  visible: boolean;
  selectSingleHotel: boolean,
  filter: GlobalFilter | null;
}

const globalFilterStore = createStore(
  { name: 'globalFilter' },
  withProps<GlobalFilterProp>({ visible: false, selectSingleHotel: false, filter: null })
);

persistState(globalFilterStore, {
  key: 'globalFilter',
  storage: localStorageStrategy,
});

@Injectable({ providedIn: 'root' })
export class GlobalFilterRepository {
  visible$: Observable<boolean> = globalFilterStore.pipe(
    select((state) => state.visible)
  );

  selectSingleHotel$: Observable<boolean> = globalFilterStore.pipe(
    select((state) => state.selectSingleHotel)
  );

  filter$: Observable<GlobalFilter | null> = globalFilterStore.pipe(
    select((state) => state.filter)
  );

  setVisible = (visible: boolean) => {
    globalFilterStore.update((state) => ({ ...state, visible }));
  };

  setSelectSingleHotel = (limit: boolean) => {
    globalFilterStore.update((state) => ({ ...state, selectSingleHotel: limit }));
  }

  setFilter = (filter: GlobalFilter) => {
    globalFilterStore.update((state) => ({ ...state, filter }));
  };
}
