<nav class="layout-breadcrumb">
    <ol>
        <ng-template ngFor let-item let-first="first" let-last="last" [ngForOf]="breadcrumbs$ | async">
            <li 
                [routerLink]="!first ? item.url : null" 
                [ngStyle]="{ 
                    cursor: (!first || !last) ? 'pointer' : 'default', 
                    color: (first || last) ? 'gray' : 'inherit', 
                    'pointer-events': (first || last) ? 'none' : 'auto' 
                }"
            >
                {{ item.label }}
            </li>
            <li *ngIf="!last" class="layout-breadcrumb-chevron"> / </li>
        </ng-template>
    </ol>
</nav>