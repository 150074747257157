<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <div class="sidebar-header">
    <a [routerLink]="['/']" class="app-logo">

      <img src="assets/images/png/logo.png" width="150" alt="CogwheelMarketing Logo">

    </a>
    <button class="layout-sidebar-anchor p-link z-2 mb-2" type="button" (click)="anchor()"></button>
  </div>


  <div #menuContainer class="layout-menu-container">
    <app-menu></app-menu>
  </div>
</div>
