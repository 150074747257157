import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LayoutService } from '@layout/service/app.layout.service';
import { AuthService } from '@core/services/auth/auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { User } from '@core/models';

@Component({
  selector: 'cwa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private primeNgConfig: PrimeNGConfig,
    private layoutService: LayoutService,
    private authService: AuthService,
    private router: Router
  ) {
    if (this.authService.token) {
      this.authService.profile().pipe(takeUntilDestroyed()).subscribe();
    }
  }

  ngOnInit(): void {
    this.primeNgConfig.ripple = true; //enables core ripple functionality

    //optional configuration with the default configuration
    this.layoutService.config = {
      ripple: false, //toggles ripple on and off
      inputStyle: 'outlined', //default style for input elements
      menuMode: 'static', //layout mode of the menu, valid values are "static", "overlay", "slim", "horizontal", "reveal" and "drawer"
      colorScheme: 'light', //color scheme of the template, valid values are "light", "dim" and "dark"
      theme: 'purple', //default component theme for PrimeNG
      menuTheme: 'colorScheme', //theme of the menu, valid values are "colorScheme", "primaryColor" and "transparent"
      scale: 14, //size of the body font size to scale the whole application
    };

    // Subscribe to route changes to trigger dataLayer push on page navigation
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd) // Filter for NavigationEnd events
      )
      .subscribe(() => {
        // Fetch user details from AuthService (modify according to your app structure)
        const user: User | any = this.authService.user; // Example method to get the user object

        // Initialize and push to window.dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'page_view',
          username: user?.firstName + user?.lastName || 'anonymous',
          enterprise: user?.enterprises || [],
          // brand: brand,
          hotel: user?.hotels || [],
        });
      });
  }
}
