<div style="background: url('assets/images/bg.jpg');" class="fixed left-0 top-0 min-h-screen min-w-screen"></div>

<div class="px-5 min-h-screen flex justify-content-center align-items-center">
  <div style="box-shadow: 3px 2px 33px -17px rgba(0,0,0,0.75);" class="border-1 surface-border surface-card border-round py-7 px-4 md:px-7 z-1">
    <div class="mb-4">
      <img src="assets/images/png/logo.png" width="150" alt="CogwheelMarketing Logo">
    </div>
    <div class="mb-4">
      <div class="text-900 text-xl font-bold mb-2">Reset Password</div>
      <span class="text-600 font-medium">Reset your new password</span>
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="resetPassword()">
      <div class="flex flex-column">
        <p-messages [(value)]="messages" [enableService]="false" [closable]="true" class="w-full md:w-25rem" />

          <div class="w-full md:w-25rem mb-4">
            <!--<span class="p-input-icon-left w-full mb-4">
              <i class="pi pi-key"></i>
              <input id="token" type="text" formControlName="token"
                     pInputText class="w-full" placeholder="Enter 6-digit code">
            </span>-->

            <span class="p-input-icon-left w-full mb-4">
              <i class="pi pi-lock"></i>
              <input id="password" type="password" formControlName="newPassword" pInputText class="w-full" placeholder="New Password">
            </span>

            <span class="p-input-icon-left w-full ">
              <i class="pi pi-lock"></i>
              <input id="confirm-password" type="password" formControlName="confirmPassword" pInputText class="w-full" placeholder="Confirm Password">
            </span>
          </div>
          <div class="flex flex-wrap gap-2 justify-content-between">
            <button pButton pRipple label="Cancel" class="flex-auto p-button-outlined" [routerLink]="['/login']"></button>
            <button
              pButton pRipple type="submit"
              label="Update"
              [loading]="loading"
              [disabled]="formGroup.invalid"
              class="flex-auto">
            </button>
          </div>
      </div>
    </form>
  </div>
</div>
