import { Component } from '@angular/core';
import { LayoutService } from '@layout/service/app.layout.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';

import { AuthService } from '@core/services/auth/auth.service';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { ToastService } from '@layout/service/toast.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'cwa-forgot-password',
  templateUrl: 'forgot-password.component.html',
})
export class ForgotPasswordComponent {
  formGroup: FormGroup;
  loading = false;
  messages!: Message[];
  control = (name: string): FormControl => this.formGroup.get(name) as FormControl;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private toast: ToastService,
    private authService: AuthService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Auth - Forgot Password | Cogwheel Analytics Tools');

    this.formGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get dark(): boolean {
    return this.layoutService.config.colorScheme !== 'light';
  }

  async requestPasswordReset(): Promise<void> {
    if (this.formGroup.invalid) return;
    try {
      const passwordResetRequest$ = this.authService.passwordResetRequest(
        this.formGroup.value
      );
      this.loading = true;
      await lastValueFrom(passwordResetRequest$);
      this.loading = false;

      this.messages = [
        {
          severity: 'success',
          summary: '',
          detail: 'If your email is registered with Cogwheel Analytics, you should receive a password reset email. Please check your inbox for further instructions.',
        },
      ];

      // await this.router.navigate(['/login']);

      this.formGroup.reset();
    } catch (e) {
      this.loading = false;
      this.toast.showError(
        'Error',
        'Your request cannot be processed at the moment. Please attempt the operation again.'
      );
    }
  }
}
