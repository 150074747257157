import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import { environment } from '@env/environment';
import { ReportQuery } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class PdfDocumentService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/report`;
  }

  downloadOverviewReport(query: { hotelId: string; dateFrom: string | Date; dateTo: string | Date; exportType: string; }): Observable<HttpResponse<Blob>> {
    const params = new HttpParams()
      .append('hotel_id', query.hotelId)
      .append('date_from', `${query.dateFrom}`)
      .append('date_to', `${query.dateTo}`)
      // .append('export_type', `${query.exportType}`);

    return this.http
      .get(`${this.baseUrl}/overview`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadNoteOverviewReport(query: string): Observable<HttpResponse<Blob>> {
    const params = new HttpParams().append('note_id', query);

    return this.http
      .get(`${this.baseUrl}/note_overview`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadChannelMixReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .get(`${this.baseUrl}/channel_mix`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadChannelMixDetailReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .get(`${this.baseUrl}/channel_mix_detail`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadVisitsRevenueReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .get(`${this.baseUrl}/visits_rev`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadVisitsRevenueDetailReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .get(`${this.baseUrl}/visits_rev_detail`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadSourceTrafficReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .get(`${this.baseUrl}/source_traffic`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadToRefDomainReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .get(`${this.baseUrl}/top_ref`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadSocialMediaReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .get(`${this.baseUrl}/social-media`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadPaidMediaOverviewReport(
    query: ReportQuery,
    mediaId?: string,
    sourceId?: string[]
  ): Observable<HttpResponse<Blob>> {
    let params = this.httpParams(query);
    params = mediaId ? params.append('mediaId', mediaId) : params;
    params = sourceId ? params.append('sourceId', sourceId.join(',')) : params;
    return this.http
      .get(`${this.baseUrl}/paid_media`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadPaidMediaDetailReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .get(`${this.baseUrl}/paid_media_detail`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  private httpParams(query: ReportQuery): HttpParams {
    let params = new HttpParams()
      .append('dateFrom', `${query.dateFrom}`)
      .append('dateTo', `${query.dateTo}`)
      .append('enterprises', query.enterprises.join(','))
      .append('hotels', query.hotels.join(','))
      .append('brands', query.brands.join(','))
      .append('predefineDate', query?.predefineDate ?? '')
      .append('is_compare', !!query.isCompare);

    params = query.hotelId ? params.append('hotel_id', query.hotelId) : params;

    params = query.exportType ? params.append('exportType', query.exportType) : params;

    params = query.sourceId ? params.append('sourceId', query.sourceId.join(',')) : params;

    params = query.isCompare
      ? params
          .append('compDateFrom', `${query.compDateFrom}`)
          .append('compDateTo', `${query.compDateTo}`)
          .append('defaultMonthDate', `${query.defaultMonthDate}`)
      : params;

    params = query.isOverview ? params.append('is_overview', query.isOverview) : params;

    return params;
  }

  

  downloadScorecardReport(query: { 
    hotelId: string; 
    date: string; 
    enterpriseId: string | undefined; 
    exportType: string;
    benchmarks?: string[];
  }): Observable<any> {
    let params = new HttpParams()
      .append('hotel_id', query.hotelId)
      .append('date', query.date)
      .append('export_type', query.exportType);
  
    if (query.enterpriseId) {
      params = params.append('enterprise_id', query.enterpriseId);
    }
  
    if (query.benchmarks && query.benchmarks.length > 0) {
      params = params.append('benchmarks', query.benchmarks.join(','));
    }
  
    return this.http.get(`${this.baseUrl}/scorecard`, {
      params,
      observe: 'response',
      responseType: 'blob',
    });
  }

  handleFileResponse(response: HttpResponse<Blob>): void {
    const contentDispositionHeader = response.headers.get('Content-Disposition');

    let fileName = '';
    if (contentDispositionHeader) {
      const matches = /filename=([^;]+)/i.exec(contentDispositionHeader);
      fileName = matches && matches[1] ? matches[1] : 'downloaded-file';

      // Use the filename as needed, for example:
      // console.log('File name:', fileName);
    }

    // The actual Blob content is in response.body
    // You can do something with the Blob, such as creating a download link
    if (response?.body) {
      const downloadLink = document.createElement('a');
      const blobUrl = URL.createObjectURL(response.body);
      downloadLink.href = blobUrl;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobUrl);
    }
  }
}
