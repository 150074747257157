import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  GoogleAdCampaign,
  GoogleAdGraph,
  GoogleAdGroup,
  GoogleAdKeyword,
} from '@core/models';
import { GoogleAdQuery } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/google`;
  }

  getGraph(query: GoogleAdQuery): Observable<GoogleAdGraph> {
    const params = this.httpParams(query);
    return this.http.get<GoogleAdGraph>(`${this.baseUrl}/graph_google_ads`, { params });
  }

  getCampaigns(query: GoogleAdQuery): Observable<GoogleAdCampaign[]> {
    const params = this.httpParams(query);
    return this.http.get<GoogleAdCampaign[]>(`${this.baseUrl}/campaign_google_ads`, {
      params,
    });
  }

  getAdGroups(campaignId: string, query: GoogleAdQuery): Observable<GoogleAdGroup[]> {
    let params = this.httpParams(query);
    params = params.append('campaign_id', campaignId);
    return this.http.get<GoogleAdGroup[]>(`${this.baseUrl}/adgroup_google_ads`, {
      params,
    });
  }

  getKeywords(groupId: string, query: GoogleAdQuery): Observable<GoogleAdKeyword[]> {
    let params = this.httpParams(query);
    params = params.append('adgroup_id', groupId);
    return this.http.get<GoogleAdKeyword[]>(`${this.baseUrl}/keyword_google_ads`, {
      params,
    });
  }

  private httpParams(query: GoogleAdQuery): HttpParams {
    let params = new HttpParams()
      .append('dateFrom', `${query.from}`)
      .append('dateTo', `${query?.to}`);

    if (query.hotelId) params = params.append('hotel_id', query.hotelId);

    return params;
  }
}
