/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import {
  catchError,
  EMPTY,
  filter,
  finalize,
  Observable,
  Subject,
  switchMap,
  take,
  throwError,
} from 'rxjs';

import { AuthToken } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: Subject<AuthToken | null> = new Subject();

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.token;
    const apiUrl =
      req.url.includes(environment.baseUrl) &&
      !req.url.includes('login/new_access_token');

    if (token && apiUrl) {
      req = this.setToken(req, token.access_token);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          (error.status === 401 || error.status === 403)
        ) {
          if (!error.url?.includes('login/new_access_token')) {
            return this.handleUnauthorizedError(req, next);
          } else {
            this.authService.logout();
            this.router.navigateByUrl('/login');
          }
        }
        return throwError(() => error);
      })
    );
  }

  handleUnauthorizedError(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: AuthToken | null) => {
          if (token) {
            // this.refreshTokenSubject.next(token);
            // return next.handle(this.setToken(req, token.access_token));
          }
          return throwError(() => '');
        }),
        catchError(() => {
          // Logout here or clear cache
          this.authService.logout();
          this.router.navigateByUrl('/login');
          return EMPTY;
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token: AuthToken | null) => token !== null),
        take(1),
        switchMap((token) => next.handle(this.setToken(req, token!.access_token)))
      );
    }
  }

  private setToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}

export const tokenInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  deps: [Router, AuthService],
  multi: true,
};
