import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@core/guards';
import { AppLayoutComponent } from '@layout/app.layout.component';
import { LoginComponent } from '@modules/auth/pages/login/login.component';
import { ForgotPasswordComponent } from '@modules/auth/pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@modules/auth/pages/reset-password/reset-password.component';
import { QueryParamGuard } from '@modules/auth/guards/query-param.guard';

const routes: Routes = [
  { path: '', redirectTo: '/brand/channel-mix', pathMatch: 'full' },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: 'profile',
        data: { breadcrumb: 'Profile' },
        canActivate: [AuthGuard],
        loadChildren: () => import('@modules/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'report',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Report' },
        loadChildren: () =>
          import('@modules/report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'brand',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Brand.com' },
        loadChildren: () =>
          import('@modules/brand/brand.module').then((m) => m.BrandModule),
      },
      {
        path: 'media',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Paid Media' },
        loadChildren: () =>
          import('@modules/paid-media/paid-media.module').then((m) => m.PaidMediaModule),
      },
      {
        path: 'social',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Social' },
        loadChildren: () => import('@modules/social/social.module').then(m => m.SocialModule)
      },
      {
        path: 'users',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Users' },
        loadChildren: () => import('@modules/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'master',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Master' },
        loadChildren: () =>
          import('@modules/master/master.module').then((m) => m.MasterModule),
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    canActivate: [QueryParamGuard],
    component: ResetPasswordComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
