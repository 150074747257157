import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  PaidMediaBrand,
  PaidMediaChannel,
  PaidMediaDetail, PaidMediaDetailCompare,
  PaidMediaGraph,
  PaidMediaHotel,
  PaidMediaOverview,
  PaidMediaSource,
  PaidMediaSourceTable
} from "@core/models";
import { PageContent, PaidMediaQuery } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class PaidMediaService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/paid_media`;
  }

  getSources(channelId: string): Observable<PaidMediaSource[]> {
    const params = new HttpParams().append('mediaId', channelId);
    return this.http.get<PaidMediaSource[]>(`${this.baseUrl}/source_list_media`, {
      params,
    });
  }

  getChannels(): Observable<PageContent<PaidMediaChannel>> {
    return this.http.get<PageContent<PaidMediaChannel>>(`${this.baseUrl}/channels`);
  }

  getOverview(query: PaidMediaQuery): Observable<PageContent<PaidMediaOverview>> {
    const params = this.httpParams(query);
    return this.http.get<PageContent<PaidMediaOverview>>(`${this.baseUrl}/overview`, {
      params,
    });
  }

  getBrands(query: PaidMediaQuery): Observable<PaidMediaBrand[]> {
    const params = this.httpParams(query);
    return this.http.get<PaidMediaBrand[]>(`${this.baseUrl}/brand_paid_media`, {
      params,
    });
  }

  getHotels(brandId: string, query: PaidMediaQuery): Observable<PaidMediaHotel[]> {
    let params = this.httpParams(query);
    params = params.append('brandId', brandId);
    return this.http.get<PaidMediaBrand[]>(`${this.baseUrl}/hotels_paid_media`, {
      params,
    });
  }

  getHotelMonths(hotelId: string, query: PaidMediaQuery): Observable<PaidMediaHotel[]> {
    let params = this.httpParams(query);
    params = params.append('hotelId', hotelId);
    return this.http.get<PaidMediaBrand[]>(`${this.baseUrl}/hotel_paid_media`, {
      params,
    });
  }

  getGraph(query: PaidMediaQuery): Observable<PaidMediaGraph[]> {
    const params = this.httpParams(query);
    return this.http.get<PaidMediaGraph[]>(`${this.baseUrl}/paid_media_graph`, {
      params,
    });
  }

  getSourceList(query: PaidMediaQuery): Observable<PaidMediaSourceTable[]> {
    const params = this.httpParams(query);
    return this.http.get<PaidMediaSourceTable[]>(
      `${this.baseUrl}/paid_media_source_list`,
      { params }
    );
  }

  getDetails(query: PaidMediaQuery): Observable<PaidMediaDetail[]> {
    const params = this.httpParams(query);
    return this.http.get<PaidMediaDetail[]>(`${this.baseUrl}/paid_media_detailed`, {
      params,
    });
  }

  getDetailNew(query: PaidMediaQuery): Observable<(PaidMediaDetail | PaidMediaDetailCompare)[]> {
    const params = this.httpParams(query);
    return this.http.get<(PaidMediaDetail | PaidMediaDetailCompare)[]>(`${this.baseUrl}/paid_media_hotel_detailed_new`, { params, });
  }

  private httpParams(query: PaidMediaQuery): HttpParams {
    let params = new HttpParams()
      .append('dateFrom', `${query.from}`)
      .append('dateTo', `${query.to}`)
      .append('mediaId', query.channelId ?? '')
      .append('isCompare', !!query?.isCompare);

    if (query.hotelId) {
      params = params.append('hotelId', query.hotelId);
    }

    if (query.enterprises && query.enterprises.length) {
      params = params.append('enterprises', query.enterprises.join(','));
    }

    if (query.brands && query.brands.length) {
      params = params.append('brands', query.brands.join(','));
    }

    if (query.hotels && query.hotels.length) {
      params = params.append('hotels', query.hotels.join(','));
    }

    if (query.sourceIds && query.sourceIds.length) {
      params = params.append('sourceId', query.sourceIds.join(','));
    }

    if (query.defaultMonthDate) {
      params = params.append('defaultMonthDate', `${query?.defaultMonthDate}`);
    }

    if (query?.compDateFrom && query.compDateTo) {
      params = params
        .append('compDateFrom', query?.compDateFrom ?? '')
        .append('compDateTo', query?.compDateTo ?? '');
    }

    params = query.predefineDate ? params
      .append('predefineDate', query?.predefineDate ?? '') : params;

    if (query.page) {
      params = params.append('page', query.page);
    }

    if (query.pageSize) {
      params = params.append('size', query.pageSize);
    }

    return params;
  }
}
